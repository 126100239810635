<template>
  <div class="wrap">
    <div class="header">
      <svg class="bnb-icon" aria-hidden="true">
        <use xlink:href="#icon-a-zu206"></use>
      </svg>
      <div class="content">
        <div class="title">MEB 社区提案</div>
        <div class="des">
          所有xMEB持有用户均可提交社区提案，获得超过5为理事支持的提案将成为治理提案，进行社区公投
        </div>
        <div class="condidate-button">提交提案</div>
      </div>
    </div>
    <PropsalList :tabList="tabList"></PropsalList>
  </div>
</template>

<script>
import PropsalList from '../components/PropsalList.vue'
export default {
  name: 'Proposal',
  data () {
    return {
      tabList: [
        {
          name: '进行中',
          key: 1
        },
        {
          name: '已结束',
          key: 3
        }
      ]
    }
  },
  methods: {},
  mounted () {
  },
  components: { PropsalList }
}
</script>

<style scoped lang="scss">
.wrap {
  padding: 20px;
  margin-bottom: 50px;
  .header {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    .bnb-icon {
      width: 110px;
      height: 30px;
    }
    .content {
      text-align: center;
      .title {
        padding-top: 15px;
        font-size: 24px;
        font-weight: bold;
        color: #000000;
      }
      .des {
        font-size: 12px;
        padding: 35px 0;
        color: #818086;
      }
      .condidate-button {
        font-size: 15px;
        // margin: 10px 0;
        color: #fff;
        font-weight: bold;
        line-height: 40px;
        height: 40px;
        background: #000;
        border-radius: 10px;
      }
    }
  }
}
</style>
